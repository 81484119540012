import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./component/home/Home";
import AboutUs from "./component/about-us/About-us";
import ServiceList from "./component/service-list/ServiceList";
import RecentWork from "./component/recent-work-page/RecentWork";
import ContactUs from "./component/contact-us/ContactUs";
import EIT from "./component/services/EIT";
import TC from "./component/services/TC";
import SSIO from "./component/services/SSIO";
import FDF from "./component/services/FDF";
import CMPM from "./component/services/CMPM";
import RC from "./component/services/RC";
import IM from "./component/services/IM";
import TCLA from "./component/services/TCLA";
import Piling from "./component/services/Piling";
import PIS from "./component/services/PIS";
import IMI from "./component/services/IMI";
import SSF from "./component/projects/SSF";
import MF from "./component/projects/MF";
import BYRF from "./component/projects/BYRF";
import UIUX from "./component/services/UIUX";
import WebsiteDesign from "./component/services/WebsiteDesign";
import MobileApplication from "./component/services/MobileApplication";
import SoftwareDevelopment from "./component/services/SoftwareDevelopment";
import It from "./component/services/It";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/service-list" element={<ServiceList />} />
        <Route path="/recent-work" element={<RecentWork />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route
          path="/services/electrical-installation-testing"
          element={<EIT />}
        />
        <Route path="/services/testing-commissioning" element={<TC />} />
        <Route
          path="/services/scada-system-installation-operation"
          element={<SSIO />}
        />
        <Route
          path="/services/continuous-monitoring-preventive-maintenance"
          element={<CMPM />}
        />
        <Route path="/services/repair-certify" element={<RC />} />
        <Route path="/services/inventory-management" element={<IM />} />
        <Route
          path="/services/trenching-cable-laying-activities"
          element={<TCLA />}
        />
        <Route path="/services/piling" element={<Piling />} />
        <Route path="/services/pv-inverter-structure" element={<PIS />} />
        <Route path="/services/ipv-module-installation" element={<IMI />} />
        <Route path="/services/fault-detection-fix" element={<FDF />} />
        <Route path="/services/ui-ux-design" element={<UIUX />} />
        <Route path="/services/website-design" element={<WebsiteDesign />} />
        <Route
          path="/services/mobile-application"
          element={<MobileApplication />}
        />
        <Route
          path="/services/software-development"
          element={<SoftwareDevelopment />}
        />
        <Route path="/services/it" element={<It />} />

        {/* Project page */}
        <Route path="/projects/stokeford-solar-farm" element={<SSF />} />
        <Route path="/projects/manor-farm" element={<MF />} />
        <Route path="/projects/bryn-y-rhyd-farm" element={<BYRF />} />
      </Routes>
    </Router>
  );
}

export default App;
