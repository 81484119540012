import React from "react";
import { useNavigate } from "react-router-dom";

const ServiceListDetail = () => {
  const router = useNavigate();
  return (
    <div>
      <section className="py-12 bg-white sm:py-16 lg:py-20 xl:py-24">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="w-full mx-auto flex lg:flex-row md:flex-row sm:flex-col flex-col items-center justify-center text-center">
            <h2 className="text-3xl text-center sm:mb-6 mb-6 font-semibold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
              Electrical Services
            </h2>
          </div>

          <div className="grid max-w-md grid-cols-1 gap-5 mx-auto mt-12 lg:grid-cols-3 sm:mt-16 sm:gap-6 lg:gap-8 lg:max-w-none">
            <div className="rounded-2xl cursor-pointer group">
              <div
                onClick={() =>
                  router("/services/electrical-installation-testing")
                }
                className="relative"
              >
                <img
                  className="object-cover mt-4 rounded-xl"
                  src="core5.png"
                  alt=""
                />
                <div className="ml-4 p-4 rounded-lg w-[80%] pt-6 bg-white absolute bottom-0 transform opacity-0 translate-y-full group-hover:opacity-100 group-hover:translate-y-[-20px] transition duration-300 ease-in-out text-xl font-semibold text-gray-900">
                  <a href="#" className="text-sm font-bold">
                    Electrical Installation
                  </a>
                  <p className="font-normal text-sm">
                    Digitech is a leading company specializing in the
                    installation, testing, ...
                  </p>
                </div>
              </div>
            </div>

            <div className="rounded-2xl cursor-pointer group">
              <div
                onClick={() => router("/services/testing-commissioning")}
                className="relative"
              >
                <img
                  className="object-cover mt-4 rounded-xl"
                  src="core4.png"
                  alt=""
                />
                <div className="ml-4 p-4 rounded-lg w-[80%] pt-6 bg-white absolute bottom-0 transform opacity-0 translate-y-full group-hover:opacity-100 group-hover:translate-y-[-20px] transition duration-300 ease-in-out text-xl font-semibold text-gray-900">
                  <p className="text-sm font-bold">Testing and Commissioning</p>
                  <p className="font-normal text-sm">
                    We adhere to all technical specifications and industrial
                    standards throughout ...
                  </p>
                </div>
              </div>
            </div>

            <div className="rounded-2xl cursor-pointer group">
              <div
                onClick={() =>
                  router("/services/scada-system-installation-operation")
                }
                className="relative"
              >
                <img
                  className="object-cover mt-4 rounded-xl"
                  src="about1.png"
                  alt=""
                />
                <div className="ml-4 p-4 rounded-lg w-[80%] pt-6 bg-white absolute bottom-0 transform opacity-0 translate-y-full group-hover:opacity-100 group-hover:translate-y-[-20px] transition duration-300 ease-in-out text-xl font-semibold text-gray-900">
                  <p className="text-sm font-bold">
                    SCADA System Installation & Operation
                  </p>
                  <p className="font-normal text-sm">
                    We install and configure the Supervisory ...
                  </p>
                </div>
              </div>
            </div>

            <div className="rounded-2xl cursor-pointer group">
              <div
                onClick={() => router("/services/fault-detection-fix")}
                className="relative"
              >
                <img
                  className="object-cover mt-4 rounded-xl"
                  src="core11.png"
                  alt=""
                />
                <div className="ml-4 p-4 rounded-lg w-[80%] pt-6 bg-white absolute bottom-0 transform opacity-0 translate-y-full group-hover:opacity-100 group-hover:translate-y-[-20px] transition duration-300 ease-in-out text-xl font-semibold text-gray-900">
                  <a href="#" className="text-sm font-bold">
                    Fault Detection and Fix
                  </a>
                  <p className="font-normal text-sm">
                    Digitech specialises in identifying and rectifying faults in
                    the solar power...
                  </p>
                </div>
              </div>
            </div>

            <div className="rounded-2xl cursor-pointer group">
              <div
                onClick={() =>
                  router(
                    "/services/continuous-monitoring-preventive-maintenance"
                  )
                }
                className="relative"
              >
                <img
                  className="object-cover mt-4 rounded-xl"
                  src="core6.png"
                  alt=""
                />
                <div className="ml-4 p-4 rounded-lg w-[80%] pt-6 bg-white absolute bottom-0 transform opacity-0 translate-y-full group-hover:opacity-100 group-hover:translate-y-[-20px] transition duration-300 ease-in-out text-xl font-semibold text-gray-900">
                  <a href="#" className="text-sm font-bold">
                    Continuous Monitoring & Preventive Maintenance
                  </a>
                  <p className="font-normal text-sm">
                    We use Drone Thermography and flash ...
                  </p>
                </div>
              </div>
            </div>

            <div className="rounded-2xl cursor-pointer group">
              <div
                onClick={() => router("/services/repair-certify")}
                className="relative"
              >
                <img
                  className="object-cover mt-4 rounded-xl"
                  src="core7.png"
                  alt=""
                />
                <div className="ml-4 p-4 rounded-lg w-[80%] pt-6 bg-white absolute bottom-0 transform opacity-0 translate-y-full group-hover:opacity-100 group-hover:translate-y-[-20px] transition duration-300 ease-in-out text-xl font-semibold text-gray-900">
                  <p className="text-sm font-bold">Repair and Certify</p>
                  <p className="font-normal text-sm">
                    We offer thorough repair, testing and certification services
                    to ensure that ...
                  </p>
                </div>
              </div>
            </div>

            <div className="rounded-2xl cursor-pointer group">
              <div
                onClick={() => router("/services/inventory-management")}
                className="relative"
              >
                <img
                  className="object-cover mt-4 rounded-xl"
                  src="core8.png"
                  alt=""
                />
                <div className="ml-4 p-4 rounded-lg w-[80%] pt-6 bg-white absolute bottom-0 transform opacity-0 translate-y-full group-hover:opacity-100 group-hover:translate-y-[-20px] transition duration-300 ease-in-out text-xl font-semibold text-gray-900">
                  <p className="text-sm font-bold">Inventory Management</p>
                  <p className="font-normal text-sm">
                    Efficient inventory management is integral to our
                    comprehensive suite ...
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full mt-16 mx-auto flex lg:flex-row md:flex-row sm:flex-col flex-col items-center justify-center text-center">
            <h2 className="text-3xl text-start sm:mb-6 mb-6 font-semibold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
              Civil & Construction Services
            </h2>
          </div>
          <div className="grid max-w-md grid-cols-1 gap-5 mx-auto mt-12 lg:grid-cols-3 sm:mt-16 sm:gap-6 lg:gap-8 lg:max-w-none">
            <div className="rounded-2xl cursor-pointer group">
              <div
                onClick={() =>
                  router("/services/trenching-cable-laying-activities")
                }
                className="relative"
              >
                <img
                  className="object-cover mt-4 rounded-xl"
                  src="core14.png"
                  alt=""
                />
                <div className="ml-4 p-4 rounded-lg w-[80%] pt-6 bg-white absolute bottom-0 transform opacity-0 translate-y-full group-hover:opacity-100 group-hover:translate-y-[-20px] transition duration-300 ease-in-out text-xl font-semibold text-gray-900">
                  <p className="text-sm font-bold">
                    Trenching and Cable Laying Activities
                  </p>
                  <p className="font-normal text-sm">
                    This involves meticulous preparation and ...
                  </p>
                </div>
              </div>
            </div>

            <div className="rounded-2xl cursor-pointer group">
              <div
                onClick={() => router("/services/piling")}
                className="relative"
              >
                <img
                  className="object-cover mt-4 rounded-xl"
                  src="core12.png"
                  alt=""
                />
                <div className="ml-4 p-4 rounded-lg w-[80%] pt-6 bg-white absolute bottom-0 transform opacity-0 translate-y-full group-hover:opacity-100 group-hover:translate-y-[-20px] transition duration-300 ease-in-out text-xl font-semibold text-gray-900">
                  <p className="text-sm font-bold">Piling</p>
                  <p className="font-normal text-sm">
                    We provide expert piling services to ensure a strong
                    foundation for solar ...
                  </p>
                </div>
              </div>
            </div>

            <div className="rounded-2xl cursor-pointer group">
              <div
                onClick={() => router("/services/pv-inverter-structure")}
                className="relative"
              >
                <img
                  className="object-cover mt-4 rounded-xl"
                  src="core10.png"
                  alt=""
                />
                <div className="ml-4 p-4 rounded-lg w-[80%] pt-6 bg-white absolute bottom-0 transform opacity-0 translate-y-full group-hover:opacity-100 group-hover:translate-y-[-20px] transition duration-300 ease-in-out text-xl font-semibold text-gray-900">
                  <p className="text-sm font-bold">PV and Inverter Structure</p>
                  <p className="font-normal text-sm">
                    Digitech excels in the design, assembly, and installation of
                    photovoltaic (PV) and ...
                  </p>
                </div>
              </div>
            </div>

            <div className="rounded-2xl cursor-pointer group">
              <div
                onClick={() => router("/services/ipv-module-installation")}
                className="relative"
              >
                <img
                  className="object-cover mt-4 rounded-xl"
                  src="core13.png"
                  alt=""
                />
                <div className="ml-4 p-4 rounded-lg w-[80%] pt-6 bg-white absolute bottom-0 transform opacity-0 translate-y-full group-hover:opacity-100 group-hover:translate-y-[-20px] transition duration-300 ease-in-out text-xl font-semibold text-gray-900">
                  <p className="text-sm font-bold">PV Module Installation</p>
                  <p className="font-normal text-sm">
                    Our team is skilled in the precise installation of PV
                    modules, ensuring ...
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full mt-16 mx-auto flex lg:flex-row md:flex-row sm:flex-col flex-col items-center justify-center text-center">
            <h2 className="text-3xl text-start sm:mb-6 mb-6 font-semibold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
              It Services
            </h2>
          </div>
          <div className="grid max-w-md grid-cols-1 gap-5 mx-auto mt-12 lg:grid-cols-3 sm:mt-16 sm:gap-6 lg:gap-8 lg:max-w-none">
            <div className="rounded-2xl cursor-pointer group">
              <div
                onClick={() => router("/services/ui-ux-design")}
                className="relative"
              >
                <img
                  className="object-cover mt-4 rounded-xl"
                  src="ux3.png"
                  alt=""
                />
                <div className="ml-4 p-4 rounded-lg w-[80%] pt-6 bg-white absolute bottom-0 transform opacity-0 translate-y-full group-hover:opacity-100 group-hover:translate-y-[-20px] transition duration-300 ease-in-out text-xl font-semibold text-gray-900">
                  <p className="text-sm font-bold">
                    UI UX audit , UX research & uI Design
                  </p>
                  <p className="font-normal text-sm">
                    This involves meticulous preparation and ...
                  </p>
                </div>
              </div>
            </div>

            <div className="rounded-2xl cursor-pointer group">
              <div
                onClick={() => router("/services/website-design")}
                className="relative"
              >
                <img
                  className="object-cover mt-4 rounded-xl"
                  src="website3.png"
                  alt=""
                />
                <div className="ml-4 p-4 rounded-lg w-[80%] pt-6 bg-white absolute bottom-0 transform opacity-0 translate-y-full group-hover:opacity-100 group-hover:translate-y-[-20px] transition duration-300 ease-in-out text-xl font-semibold text-gray-900">
                  <p className="text-sm font-bold">
                    Website Design & Development
                  </p>
                  <p className="font-normal text-sm">
                    Digitech excels in the design, assembly, and installation of
                    photovoltaic (PV) and ...
                  </p>
                </div>
              </div>
            </div>

            <div className="rounded-2xl cursor-pointer group">
              <div
                onClick={() => router("/services/mobile-application")}
                className="relative"
              >
                <img
                  className="object-cover mt-4 rounded-xl"
                  src="mobile3.png"
                  alt=""
                />
                <div className="ml-4 p-4 rounded-lg w-[80%] pt-6 bg-white absolute bottom-0 transform opacity-0 translate-y-full group-hover:opacity-100 group-hover:translate-y-[-20px] transition duration-300 ease-in-out text-xl font-semibold text-gray-900">
                  <p className="text-sm font-bold">Mobile Application Design</p>
                  <p className="font-normal text-sm">
                    We provide expert piling services to ensure a strong
                    foundation for sola ...
                  </p>
                </div>
              </div>
            </div>

            <div className="rounded-2xl cursor-pointer group">
              <div
                onClick={() => router("/services/software-development")}
                className="relative"
              >
                <img
                  className="object-cover mt-4 rounded-xl"
                  src="software3.png"
                  alt=""
                />
                <div className="ml-4 p-4 rounded-lg w-[80%] pt-6 bg-white absolute bottom-0 transform opacity-0 translate-y-full group-hover:opacity-100 group-hover:translate-y-[-20px] transition duration-300 ease-in-out text-xl font-semibold text-gray-900">
                  <p className="text-sm font-bold">
                    Software Design & development{" "}
                  </p>
                  <p className="font-normal text-sm">
                    Our team is skilled in the precise installation ...
                  </p>
                </div>
              </div>
            </div>
            <div className="rounded-2xl cursor-pointer group">
              <div onClick={() => router("/services/it")} className="relative">
                <img
                  className="object-cover mt-4 rounded-xl"
                  src="it3.png"
                  alt=""
                />
                <div className="ml-4 p-4 rounded-lg w-[80%] pt-6 bg-white absolute bottom-0 transform opacity-0 translate-y-full group-hover:opacity-100 group-hover:translate-y-[-20px] transition duration-300 ease-in-out text-xl font-semibold text-gray-900">
                  <p className="text-sm font-bold">It Support </p>
                  <p className="font-normal text-sm">
                    Administering and designing LANs, WANs ...
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServiceListDetail;
