import React from "react";
import { TypeAnimation } from "react-type-animation";
import "./style.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

const Hero = () => {
  const slides = [
    {
      image: "/banner1.png",
      text: "Leading Solar Solutions:\nInstallation, Testing, and Maintenance",
    },
    {
      image: "/banner1.png",
      text: "Innovative Energy Solutions\nfor a Sustainable Future",
    },
    {
      image: "/banner1.png",
      text: "Expert Solar Panel Installation\nand Maintenance Services",
    },
  ];

  return (
    <div className="relative">
      <section className="relative pb-12 bg-white  sm:pb-16  lg:pb-20  xl:pb-32">
        <Swiper
          pagination={{ clickable: true }}
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          className="relative"
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index} className="relative">
              <img
                className="object-cover w-full h-[1000px] md:h-[900px]"
                src={slide.image}
                alt={`Banner ${index + 1}`}
              />
              <div className="absolute inset-0 left-0  right-0 flex items-center justify-center z-10">
                <div className="w-full px-4 text-start md:max-w-xs lg:max-w-6xl">
                  <p className="text-[#2DD4C0] font-bold text-xl">Digitech</p>
                  <div className="min-h-[110px] text-white">
                    <p className="lg:text-white sm:text-black text-black">
                      <TypeAnimation
                        className="text-4xl !text-[#fff] font-bold sm:text-5xl"
                        sequence={[slide.text, 1000, ""]}
                        repeat={Infinity}
                        style={{
                          whiteSpace: "pre-line",
                        }}
                      />
                    </p>
                  </div>
                  <p className="mt-5 text-medium max-w-xl font-normal leading-7 lg:text-white sm:text-black text-white">
                    We provide practical solutions that not only meet the needs
                    of today but also ensure a sustainable and prosperous
                    tomorrow.
                  </p>
                  <div className="relative inline-flex mt-10 group">
                    <div className="absolute transition-all duration-1000 opacity-70 inset-0 rounded-xl blur-lg filter group-hover:opacity-100 group-hover:duration-200"></div>
                    <a
                      href="/contact-us"
                      title=""
                      className="relative inline-flex items-center justify-center px-8 py-3 text-base font-bold text-white transition-all duration-200 bg-[#23A999] border border-transparent rounded-full hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                      role="button"
                    >
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </div>
  );
};

export default Hero;
