import React from "react";
import Team from "../team/Team";

const AboutUsDetails = () => {
  return (
    <div>
      <section className="relative py-12 bg-gray-900 sm:py-16 lg:py-20 xl:py-32">
        <div className="absolute inset-0">
          <img
            className="object-cover w-full h-full"
            src="service-banner1.png"
            alt=""
          />
        </div>

        <div className="absolute inset-0 bg-gray-900/50"></div>

        <div className="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-5xl mx-auto text-center">
            <p className="text-[#23A999] font-medium font-nunito-sans text-xl uppercase">
              about digitech
            </p>
            <h2 className="text-3xl font-bold text-white font-nunito-sans sm:text-4xl lg:text-5xl">
              Sustainable Energy Experts
            </h2>
            <p className="mt-4 text-base font-normal leading-7 text-white sm:mt-6">
              Digitech, a leading UK-based company, specializes in the
              electrical and civil installation, testing, and commissioning of
              solar farms, prioritizing quality, safety, and efficiency.
              Established with a commitment to promoting sustainable energy
              solutions, Digitech has become a trusted name in the renewable
              energy sector.
            </p>
          </div>
        </div>
      </section>
      <div className="bg-[#f0f0f0] w-full">
        <div className=" pt-[100px] z-[10px] flex items-center  justify-center">
          <img src="about3.png" />
        </div>
        <section class="py-12 bg-transparent sm:py-16 lg:py-20">
          <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div class="grid grid-cols-1 text-center gap-y-10 md:grid-cols-3 lg:grid-cols-4 md:text-left">
              <div class="md:pr-6 lg:pr-12 bg-white rounded-xl mr-3 p-6">
                <img src="icon7.png" />

                <h3 class="mt-12 text-lg font-bold text-gray-900 dm-sans">
                  Efficiency & Power
                </h3>
              </div>

              {/* <div class="w-56 h-px mx-auto bg-gray-200 md:hidden"></div> */}

              <div class="md:px-6 lg:px-12 md:border-l  bg-white rounded-xl mr-2 p-6 md:border-gray-200">
                <img src="icon4.png" />

                <h3 class="mt-12 text-lg font-bold text-gray-900 dm-sans">
                  Trust & Warranty
                </h3>
              </div>

              <div class="w-56 h-px mx-auto bg-gray-200 md:hidden"></div>

              <div class="md:pl-6 lg:pl-12 md:border-l  bg-white rounded-xl p-6  md:border-gray-200">
                <img src="icon5.png" />
                <h3 class="mt-12 text-lg font-bold text-gray-900 dm-sans">
                  High Quality Work
                </h3>
              </div>
              <div class="md:pl-6 lg:pl-12 md:border-l  bg-white rounded-xl p-6  md:border-gray-200">
                <img src="icon7.png" />

                <h3 class="mt-12 text-lg font-bold text-gray-900 dm-sans">
                  24*7 Support
                </h3>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="py-12 bg-white sm:py-16 lg:py-20 xl:py-24">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="grid grid-cols-1 gap-y-12 lg:grid-cols-7 lg:gap-x-20">
            <div className="lg:col-span-3 lg:order-last">
              <div className="relative overflow-hidden">
                <img
                  className="object-cover w-full h-full"
                  src="about4.png"
                  alt=""
                />
                <div className="absolute bottom-0 left-0">
                  <a
                    href="#"
                    title=""
                    className="inline-flex items-center px-5 py-3 text-base font-normal bg-black"
                    role="button"
                  >
                    <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500">
                      {" "}
                      Mission Statement{" "}
                    </span>
                    <svg
                      className="w-auto h-5 ml-2"
                      viewBox="0 0 19 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11 3.75L16.25 9M16.25 9L11 14.25M16.25 9L2.75 9"
                        stroke="url(#paint0_linear_523_1470)"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_523_1470"
                          x1="2.75"
                          y1="3.75"
                          x2="17.6868"
                          y2="6.52979"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#6DDCFF" />
                          <stop offset="1" stop-color="#7F60F9" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </a>
                </div>
              </div>
            </div>

            <div className="lg:col-span-4">
              <h2 className="text-3xl font-normal text-black sm:text-4xl lg:text-5xl xl:text-6xl">
                Mission Statement{" "}
              </h2>

              <p className="mt-8 text-lg font-normal text-black">
                The mission of Digitech is to provide innovative, reliable, and
                cost-effective solar farm installations that enable our
                customers to thrive in an increasingly renewable energy focused
                environment. Our aim is to be the most trusted partner for our
                clients, our community, and the planet. At our core, we strive
                to engineer the future responsibly. Through smart innovation and
                a commitment to efficiency and safety, we provide practical
                solutions that not only meet the needs of today but also ensure
                a sustainable and prosperous tomorrow.
              </p>

              <hr className="mt-8 border-gray-800 sm:mt-12" />

              {/* <ul className="grid grid-cols-1 mt-8 sm:grid-cols-2 sm:mt-12 gap-x-12 gap-y-8 sm:gap-y-12">
                <li className="flex items-start">
                  <svg
                    className="w-6 h-6 shrink-0"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 13L9 17L19 7"
                      stroke="url(#paint0_linear_515_2603)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_515_2603"
                        x1="5"
                        y1="7"
                        x2="20.3943"
                        y2="10.1196"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#6DDCFF" />
                        <stop offset="1" stop-color="#7F60F9" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <span className="ml-4 text-lg font-normal text-black">
                    {" "}
                    Get unlimited design inspirations. Level up your design.{" "}
                  </span>
                </li>

                <li className="flex items-start">
                  <svg
                    className="w-6 h-6 shrink-0"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 13L9 17L19 7"
                      stroke="url(#paint0_linear_515_2603)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_515_2603"
                        x1="5"
                        y1="7"
                        x2="20.3943"
                        y2="10.1196"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#6DDCFF" />
                        <stop offset="1" stop-color="#7F60F9" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <span className="ml-4 text-lg font-normal text-black">
                    {" "}
                    14+ Premium tailwind UI kits. Start with unlimited product
                    downloads.{" "}
                  </span>
                </li>

                <li className="flex items-start">
                  <svg
                    className="w-6 h-6 shrink-0"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 13L9 17L19 7"
                      stroke="url(#paint0_linear_515_2603)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_515_2603"
                        x1="5"
                        y1="7"
                        x2="20.3943"
                        y2="10.1196"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#6DDCFF" />
                        <stop offset="1" stop-color="#7F60F9" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <span className="ml-4 text-lg font-normal text-black">
                    {" "}
                    Submit your design. Get extra exposure on works to grow
                    fast.{" "}
                  </span>
                </li>

                <li className="flex items-start">
                  <svg
                    className="w-6 h-6 shrink-0"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 13L9 17L19 7"
                      stroke="url(#paint0_linear_515_2603)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_515_2603"
                        x1="5"
                        y1="7"
                        x2="20.3943"
                        y2="10.1196"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#6DDCFF" />
                        <stop offset="1" stop-color="#7F60F9" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <span className="ml-4 text-lg font-normal text-black">
                    {" "}
                    Get unlimited design inspirations. Level up your design.{" "}
                  </span>
                </li>
              </ul> */}
            </div>
          </div>
        </div>
      </section>
      <section className="py-10 bg-white sm:py-16 lg:py-24">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <h2 className="text-3xl font-bold leading-tight text-black sm:text-4xl lg:text-5xl">
              Our Work Process
            </h2>
          </div>

          <div className="relative mt-12 lg:mt-20">
            <div className="absolute inset-x-0 hidden xl:px-44 top-2 md:block md:px-20 lg:px-28">
              <img
                className="w-full"
                src="https://cdn.rareblocks.xyz/collection/celebration/images/steps/2/curved-dotted-line.svg"
                alt=""
              />
            </div>

            <div className="relative grid grid-cols-1 text-center gap-y-12 md:grid-cols-3 gap-x-12">
              <div>
                <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                  <span className="text-xl font-semibold text-gray-700">
                    {" "}
                    <img src="icon2.png" />
                  </span>
                </div>
                <h3 className="mt-6 text-xl font-semibold leading-tight text-black md:mt-10">
                  Project Planning
                </h3>
              </div>

              <div>
                <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                  <span className="text-xl font-semibold text-gray-700">
                    {" "}
                    <img src="icon1.png" />
                  </span>
                </div>
                <h3 className="mt-6 text-xl font-semibold leading-tight text-black md:mt-10">
                  Research & Analysis
                </h3>
              </div>

              <div>
                <div className="flex items-center justify-center w-16 h-16 mx-auto bg-white border-2 border-gray-200 rounded-full shadow">
                  <span className="text-xl font-semibold text-gray-700">
                    {" "}
                    <img src="icon3.png" />
                  </span>
                </div>
                <h3 className="mt-6 text-xl font-semibold leading-tight text-black md:mt-10">
                  Solar Installation
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Team />
    </div>
  );
};

export default AboutUsDetails;
