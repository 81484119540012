import React from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import AboutUsDetails from "./AboutUsDetails";

const AboutUs = () => {
  return (
    <div>
      <Header />
      <AboutUsDetails />
      <Footer />
    </div>
  );
};

export default AboutUs;
