import React from "react";
import { IoMailOutline } from "react-icons/io5";

import { CiLocationOn } from "react-icons/ci";
import { SlPhone } from "react-icons/sl";
const Location = () => {
  return (
    <div>
      <section className="py-12 bg-[#E4F2EC]  mx-auto mt-10 sm:py-16 lg:py-32">
        <div className="px-4  mx-auto flex flex-col lg:flex-row md:flex-row sm:flex-row items-center justify-center sm:px-6 lg:px-8 max-w-7xl">
          <div className="grid grid-cols-1 gap-y-8 lg:ml-[100px] lg:grid-cols-7 lg:gap-x-12">
            <div className="lg:col-span-3">
              <div>
                <p className="text-black text-start font-nunito-sans  3xl:text-[48px] 2xl:text-5xl xl:text-5xl lg:text-5xl md:text-4xl sm:text-3xl text-3xl  lg:text-start md:text-start sm:text-center  font-normal mb-5">
                  Contact Us for Sustainable Energy Solutions
                </p>
                <p className="text-sm my-5">
                  Interested in partnering with us for your solar project? Reach
                  out to our expert team today for a consultation.
                </p>
              </div>
            </div>

            {/* <div className="bg-gray-100  mr-10 lg:col-span-4 rounded-xl">
              <div className="px-4 w-full !h-[400px]  sm:p-6 ">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2434.430184733284!2d-1.2711676228348123!3d52.398879272029774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19b67897f9dc86f%3A0xea719f57ed74f8c4!2sAdexa%20Direct%20(HGV%20%26%20Car%20entrance)!5e0!3m2!1sen!2sbd!4v1716829920302!5m2!1sen!2sbd"
                  width="600"
                  height="450"
                  // style="border:0;"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div> */}
          </div>
          <div className="lg:mt-[-40px] md:mt-[-40px] sm:mt-[-40px]">
            <div className="flex items-center flex-row px-5  ">
              <IoMailOutline size={24} />
              <p className="ml-5 text-[#0a243a] text-center text-lg font-normal">
                info@digitechworlduk.com
              </p>
            </div>

            <div className="flex items-center flex-row px-5 pt-4 ">
              <SlPhone size={24} />
              <p className="ml-5 text-[#0a243a] text-center text-lg font-normal">
                03300430386
              </p>
            </div>
            <div className="flex items-start flex-row px-5  py-5">
              <CiLocationOn size={58} className="mt-[-5px]" />
              <p className=" !ml-[15px] text-[#0a243a] text-start text-lg font-normal">
                61 Somers Road Industrial Estate Rugby, England, CV22 7DG
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Location;
