import React from "react";
import { useNavigate } from "react-router-dom";

const RecentWork = ({ bg, color }) => {
  const router = useNavigate();
  return (
    <div>
      <section
        style={{
          backgroundColor: bg,
        }}
        className="py-12 sm:py-16 lg:py-20 xl:py-24"
      >
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-[30px] font-nunito-sans text-bold text-white sm:text-4xl lg:text-5xl xl:text-[60px]">
              Our Recent Work
            </h2>
            <p className="mt-6 text-lg font-normal text-white">
              Digitech has a proven track record of delivering top-notch
              mechanical and electrical services for a variety of solar energy
              projects. Below is a detailed overview of some of our significant
              projects, showcasing our expertise and commitment to excellence.
            </p>
          </div>

          <div className="max-w-7xl mx-auto mt-12 space-y-12 md:space-y-20 sm:mt-16 lg:mt-20">
            <div className="grid items-center grid-cols-1 md:grid-cols-2 md:gap-x-16 gap-y-8">
              <div>
                <img className="w-full rounded-md" src="recent1.png" alt="" />
              </div>
              <div
                className="cursor-pointer"
                // onClick={() => router("/projects/stokeford-solar-farm")}
              >
                <h3
                  style={{
                    color: color,
                  }}
                  className="mt-6 text-3xl font-normal  lg:mt-12"
                >
                  Stokeford Solar Farm
                </h3>
                <p
                  style={{
                    color: color,
                  }}
                  className="text-white mt-3 text-bold text-xl"
                >
                  Dorset, UK
                </p>
                <p
                  style={{
                    color: color,
                  }}
                  className="text-white mt-2 max-w-sm"
                >
                  Comprehensive mechanical and electrical installations,
                  including testing and commissioning.
                </p>

                {/* <a
                  href="/projects/stokeford-solar-farm"
                  className="text-[#23A999] hover:underline font-medium text-lg mt-4"
                >
                  Read More
                </a> */}
              </div>
            </div>

            <div className="grid items-center grid-cols-1 md:grid-cols-2 md:gap-x-16 gap-y-8">
              <div className="md:order-last">
                <img className="w-full rounded-md" src="recent2.png" alt="" />
              </div>

              <div>
                <div
                  // onClick={() => router("/projects/bryn-y-rhyd-farm")}
                  className="relative w-11 h-11 cursor-pointer"
                ></div>
                <h3
                  style={{
                    color: color,
                  }}
                  className="mt-6 text-3xl font-normal text-white lg:mt-12"
                >
                  Bryn-Y-Rhyd Farm:
                </h3>
                <p
                  style={{
                    color: color,
                  }}
                  className="text-white mt-3 text-bold text-xl"
                >
                  Wales, UK
                </p>
                <p
                  style={{
                    color: color,
                  }}
                  className="text-white mt-2 max-w-sm"
                >
                  Ongoing project involving the full mechanical scope from pile
                  ramming, mounting structure, and module installation to torque
                  checking, ensuring the complete setup of the solar farm’s
                  structural components.
                </p>

                {/* <p className="mt-3 text-base font-normal text-gray-400">
                  This Solar Installation is designed to supply renewable energy
                  to a rural area, requiring extensive mechanical and electrical
                  work to meet the specific needs of the site.
                </p> */}
                {/* <a
                  href="/projects/manor-farm"
                  className="text-[#23A999] font-medium hover:underline text-lg mt-4"
                >
                  Read More
                </a> */}
              </div>
            </div>

            <div className="grid items-center grid-cols-1 md:grid-cols-2 md:gap-x-16 gap-y-8">
              <div>
                <img className="w-full rounded-md" src="recent3.png" alt="" />
              </div>
              <div>
                <div
                  // onClick={() => router("/projects/bryn-y-rhyd-farm")}
                  className="relative w-11 h-11 cursor-pointer"
                ></div>
                <h3
                  style={{
                    color: color,
                  }}
                  className="mt-6 text-3xl font-normal text-white lg:mt-12"
                >
                  Manor Farm
                </h3>
                <p
                  style={{
                    color: color,
                  }}
                  className="text-white mt-3 text-bold text-xl"
                >
                  Skegness, UK{" "}
                </p>
                <p
                  style={{
                    color: color,
                  }}
                  className="text-white mt-2 max-w-sm"
                >
                  Full scope of electrical installations, SCADA implementation,
                  and performance monitoring.
                </p>

                {/* <p className="mt-3 text-base font-normal text-gray-400">
                  This Solar Project was a medium-scale installation aimed at
                  providing renewable energy to the surrounding community. This
                  project required a balanced approach to both mechanical and
                  electrical tasks.
                </p> */}
                {/* <a
                  href="/projects/bryn-y-rhyd-farm"
                  className="text-[#23A999] font-medium hover:underline text-lg mt-4"
                >
                  Read More
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RecentWork;
