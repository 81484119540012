import React from "react";
import RecentWork from "../recent-work/RecentWork";

const RecentWorkDetails = () => {
  return (
    <div className="mt-[-200px]">
      <RecentWork bg={"#fff"} color={"#000"} />
    </div>
  );
};

export default RecentWorkDetails;
