import React from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import RecentWorkHero from "./RecentWorkHero";
import RecentWorkDetails from "./RecentWorkDetails";

const RecentWork = () => {
  return (
    <div>
      <Header />
      <RecentWorkHero />
      <RecentWorkDetails />
      <Footer />
    </div>
  );
};

export default RecentWork;
