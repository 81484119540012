import React from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import ServiceLayout from "./ServiceLayout";

const MobileApplication = () => {
  return (
    <div>
      <Header />
      <section className="relative py-12 bg-gray-900 sm:py-16 lg:py-20 xl:py-32">
        <div className="absolute inset-0">
          <img
            className="object-cover w-full h-full"
            src="/mobile.png"
            alt=""
          />
        </div>

        <div className="absolute inset-0 bg-gray-900/50"></div>

        <div className="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-5xl mx-auto text-center">
            <p className="text-[#23A999] font-medium font-nunito-sans text-xl uppercase">
              Our services
            </p>
            <h2 className="text-3xl font-bold text-white sm:text-4xl font-nunito-sans lg:text-5xl">
            Mobile Application Design
            </h2>
          </div>
        </div>
      </section>

      <section className="py-12 bg-white sm:py-16 lg:py-20">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-[1450px]">
          <div className="grid grid-cols-1 gap-y-8 lg:grid-cols-7 ">
            <ServiceLayout />

            <div className=" lg:col-span-4 rounded-xl h-[1400px] pt-0 pb-12 overflow-y-auto [&::-webkit-scrollbar]:hidden">
              <section className="py-12 bg-white sm:py-16 lg:py-2">
                <div className="px-4 mx-auto w-full sm:px-6 lg:px-2">
                  <div className="max-w-3xl mx-auto xl:max-w-4xl">
                    <div className="relative ">
                      <div className="absolute -inset-2">
                        <div className="w-full h-full mx-auto opacity-30 blur-lg filter"></div>
                      </div>

                      <img
                        className="relative rounded-xl"
                        src="/mobile2.png"
                        alt=""
                      />
                    </div>
                    <h1 className="text-[38px] font-nunito-sans font-bold text-gray-900 mt-7 sm:text-3xl xl:text-[38px] ">
                    Mobile Application Design
                    </h1>

                    <div className="mt-10"></div>

                    <div className="mt-10">
                      <p className="text-base font-normal leading-7 text-gray-700 font-dm-sans">
                      We excel in mobile application design, crafting intuitive and beautiful apps that prioritize user needs. Our design process ensures seamless navigation and engaging user experiences across all devices. We focus on user research and data-driven design to create apps that are both functional and visually appealing. Our team builds prototypes for early feedback, ensuring cost-effective design decisions. Achieve your business goals with our expert mobile app design services.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default MobileApplication;
