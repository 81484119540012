import React from "react";

const RecentWorkHero = () => {
  return (
    <div>
      <section className="relative py-12 bg-gray-900 sm:py-16 lg:py-20 xl:py-32">
        <div className="absolute inset-0">
          <img
            className="object-cover w-full h-full"
            src="service-banner1.png"
            alt=""
          />
        </div>

        <div className="absolute inset-0 bg-gray-900/50"></div>

        <div className="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-5xl mx-auto text-center">
            <p className="text-[#23A999] font-medium text-xl">
              RECENT PROJECTS
            </p>
            <h2 className="text-3xl font-bold text-white sm:text-4xl lg:text-5xl">
              Our Success Stories
            </h2>
            <p className="mt-4 text-base font-normal leading-7 text-white sm:mt-6">
              Digitech has a proven track record of delivering top-notch
              mechanical and electrical services for a variety of solar energy
              projects. Below is a detailed overview of some of our significant
              projects, showcasing our expertise and commitment to excellence.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RecentWorkHero;
