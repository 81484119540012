import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

const galleryFilter = [
  {
    id: 1,
    name: "Electrical Services",
  },
  {
    id: 3,
    name: "Civil & Construction",
  },
  {
    id: 3,
    name: "It Services",
  },
];

const electricalServices = [
  {
    id: 1,
    title: "Electrical Installation",
    description:
      "Digitech is a leading company specializing in the installation, testing, ...",
    image: "core4.png",
  },
  {
    id: 2,
    title: "Testing and Commissioning",
    description:
      "We adhere to all technical specifications and industrial standards throughout ...",
    image: "core5.png",
  },
  {
    id: 3,
    title: "SCADA System Installation & Operation",
    description: "We install and configure the Supervisory ...",
    image: "core15.png",
  },
];

const civilServices = [
  {
    id: 1,
    title: "Trenching and Cable Laying Activities",
    description: "This involves meticulous preparation and ...",
    image: "core14.png",
  },
  {
    id: 2,
    title: "Piling",
    description:
      "We provide expert piling services to ensure a strong foundation for solar ...",
    image: "core12.png",
  },
  {
    id: 3,
    title: "PV Module Installation",
    description:
      "Our team is skilled in the precise installation of PV modules, ensuring ...",
    image: "core13.png",
  },
];
const itServices = [
  {
    id: 1,
    title: "UI UX audit , UX research & uI Design",
    description: "This involves meticulous preparation and  ...",
    image: "ux3.png",
  },
  {
    id: 2,
    title: "Website Design & Development",
    description:
      "Digitech excels in the design, assembly, and installation of photovoltaic (PV) and  ...",
    image: "website3.png",
  },
  {
    id: 3,
    title: "Mobile Application Design",
    description:
      "We provide expert piling services to ensure a strong foundation for sola ...",
    image: "mobile3.png",
  },
  {
    id: 4,
    title: "Software Design  & development ",
    description: "Our team is skilled in the precise installation ...",
    image: "software3.png",
  },
  {
    id: 5,
    title: "IT support",
    description: "Administering and designing LANs, WANs ...",
    image: "it3.png",
  },
];

const CoreService = () => {
  const [activeIndex, setActiveIndex] = useState("Electrical Services");
  const [services, setServices] = useState(electricalServices);
  const navigate = useNavigate();

  const handleActiveIndex = useCallback((name) => {
    setActiveIndex(name);
    setServices(
      name === "Electrical Services"
        ? electricalServices
        : name === "It Services"
        ? itServices
        : civilServices
    );
  }, []);

  return (
    <div>
      <section className="py-12 bg-white sm:py-16 lg:py-20 xl:py-24">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-lg mx-auto text-center">
            <h2 className="text-[30px] font-nunito-sans font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-[60px]">
              Core Services
            </h2>
          </div>
          <div className="flex my-[40px] justify-center items-center flex-row">
            {galleryFilter?.map((item, index) => (
              <div
                key={index}
                className={`h-[50px] cursor-pointer border border-[#d7dee5] flex items-center justify-center rounded w-fit px-8 ${
                  activeIndex === item.name ? "bg-[#23A999]" : ""
                }`}
                onClick={() => handleActiveIndex(item.name)}
              >
                <p
                  className={`font-medium lg:text-[16px] md:text-[16px] sm:text-[16px] text-[12px] ${
                    activeIndex === item.name ? "text-[#fff]" : "text-[#003768]"
                  }`}
                >
                  {item?.name}
                </p>
              </div>
            ))}
          </div>

          <div className="grid max-w-md grid-cols-1 mx-auto mt-12 lg:grid-cols-3 sm:mt-16 lg:max-w-none gap-8">
            {services.map((service) => (
              <div
                key={service.id}
                className="rounded-2xl cursor-pointer group"
              >
                <div
                  className="relative"
                  onClick={() =>
                    navigate(
                      `/services/${service.title
                        .toLowerCase()
                        .replace(/ /g, "-")}`
                    )
                  }
                >
                  <img
                    className="object-cover mt-4 rounded-xl"
                    src={service.image}
                    alt={service.title}
                  />
                  <div className="ml-4 p-4 rounded-lg w-[80%] pt-6 bg-white absolute bottom-0 transform opacity-0 translate-y-full group-hover:opacity-100 group-hover:translate-y-[-20px] transition duration-300 ease-in-out text-xl font-semibold text-gray-900">
                    <p className="text-sm font-bold">{service.title}</p>
                    <p className="font-normal text-sm">{service.description}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="relative flex items-center justify-center w-full inline-flex mt-5 group">
            <div className="absolute transition-all duration-1000 opacity-70 inset-0 rounded-xl blur-lg filter group-hover:opacity-100 group-hover:duration-200"></div>
            <a
              href="/service-list"
              title=""
              className="relative px-8 py-3 text-base font-bold text-white transition-all duration-200 bg-[#23A999] border border-transparent rounded-full hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
              role="button"
            >
              See All
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CoreService;
