import React from "react";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import ServiceHero from "./ServiceHero";
import ServiceListDetail from "./ServiceListDetail";

const ServiceList = () => {
  return (
    <div>
      <Header />
      <ServiceHero />
      <ServiceListDetail />
      <Footer />
    </div>
  );
};

export default ServiceList;
