import React from "react";

const HomeAbout = () => {
  return (
    <div>
      <section className="py-10 bg-gray-50 sm:py-16 lg:py-24">
        <div className="max-w-5xl px-4 mx-auto sm:px-6 lg:px-8">
          <div className="grid items-center md:grid-cols-2 md:gap-x-20 gap-y-10">
            <div className="relative pl-16 pr-10 sm:pl-6 md:pl-0 xl:pr-0 md:order-2">
             

              <div className="relative max-w-xs ml-auto">
                <div className="overflow-hidden flex flex-row aspect-w-3 aspect-h-4">
                  <img
                    className="object-cover w-full   h-full "
                    src="about6.png"
                    alt=""
                  />
                </div>
                {/* <img
                  className="object-cover absolute top-[-10%] left-[100%] w-full  h-full "
                  src="about5.png"
                  alt=""
                /> */}
              </div>
            </div>

            <div className="md:order-1">
              <h2 className="text-[30px] mb-[30px] font-nunito-sans font-bold leading-tight text-black sm:text-4xl lg:text-[60px]">
                About US
              </h2>
              <p className="mt-4 text-base leading-relaxed text-gray-600">
                Digitech, a leading UK-based company, specializes in the
                electrical and civil installation, testing, and commissioning of
                solar farms, prioritizing quality, safety, and efficiency.
                Established with a commitment to promoting sustainable energy
                solutions, Digitech has become a trusted name in the renewable
                energy sector.
              </p>

              <div className="relative inline-flex mt-10 group">
                <div className="absolute transitiona-all duration-1000 opacity-70 inset-0 rounded-xl blur-lg filter group-hover:opacity-100 group-hover:duration-200"></div>

                <a
                  href="#"
                  title=""
                  className="relative inline-flex items-center justify-center px-8 py-3 text-base font-bold text-white transition-all duration-200 bg-[#23A999] border border-transparent rounded-full hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                  role="button"
                >
                  Read More
                </a>
              </div>
              {/* <img
                className="object-contain ml-auto  mt-10 w-[60%]  h-[60%] "
                src="about2.png"
                alt=""
              /> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeAbout;
