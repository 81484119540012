import React from "react";

const Team = () => {
  return (
    <div>
      <section className="py-12 bg-white sm:py-16 lg:py-20">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-[30px] font-nunito-sans text-bold text-gray-900 sm:text-4xl xl:text-[60px]  ">
              Our Three directors
            </h2>
          </div>

          <div className="grid max-w-6xl grid-cols-1 px-20 flex items-center justify-center mt-12 text-center sm:px-0 sm:grid-cols-2 lg:ml-[210px] md:mt-20 gap-x-8 md:grid-cols-4 gap-y-12 lg:gap-x-16 xl:gap-x-20">
            <div>
              <img
                className="object-cover w-32 h-32 mx-auto rounded-full lg:w-52 lg:h-52  filter"
                src="team4.png"
                alt=""
              />
              <p className="mt-5 text-lg xl!:text-xl font-bold text-black sm:text-xl sm:mt-8 ">
                Omar Sharif
              </p>
              <p className="mt-2 text-base font-normal text-gray-600 font-pj">
                Electrical Engineer
              </p>
              <p className="mt-2 text-base font-normal text-gray-600 font-pj">
                (10+ years experience)
              </p>
            </div>

            <div>
              <img
                className="object-cover w-32 h-32 mx-auto rounded-full lg:w-52 lg:h-52  filter"
                src="team5.png"
                alt=""
              />
              <p className="mt-5 text-lg font-bold text-gray-900 sm:text-xl sm:mt-8 font-pj">
                Iftaker Fahim
              </p>
              <p className="mt-2 text-base font-normal text-gray-600 font-pj">
                Control and Instrument Engineer
              </p>
              <p className="mt-2 text-base font-normal text-gray-600 font-pj">
                (12 years experience)
              </p>
            </div>

            <div>
              <img
                className="object-cover w-32 h-32 mx-auto rounded-full lg:w-52 lg:h-52  filter"
                src="team6.png"
                alt=""
              />
              <p className="mt-5 text-lg font-bold text-gray-900 sm:text-xl sm:mt-8 font-pj">
                Shovon Mostofa
              </p>
              <p className="mt-2 text-base font-normal text-gray-600 font-pj">
                Mechanical Engineer
              </p>
              <p className="mt-2 text-base font-normal text-gray-600 font-pj">
                (10+ years experience)
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Team;
