import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const ProjectsLayout = () => {
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  return (
    <div className="lg:col-span-3 bg-[#fafafa] rounded-lg p-3 max-w-md h-fit">
      <div>
        <button
          className="text-xl bg-[#23A999] py-3 px-2 font-bold font-nunito-sans text-white w-full text-left flex items-center justify-between"
          onClick={() => toggleDropdown("stokeford")}
        >
          Stokeford Solar Farm
          {openDropdown === "stokeford" ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </button>
        {openDropdown === "stokeford" && (
          <div className="mt-6 space-y-5">
            <div className="relative overflow-hidden transition-all duration-200 rounded-lg hover:shadow-lg hover:bg-gray-50 hover:-translate-y-1">
              <div className="px-4">
                <div className="flex items-start lg:items-center">
                  <div className="ml-5">
                    <p className="text-[16px] leading-7 font-normal py-1 font-dm-sans text-gray-900">
                      <a href="/projects/stokeford-solar-farm" title="">
                        <p className="text-xl font-dm-sans font-medium">
                          overview
                        </p>

                        <p className="mt-6 text-base font-medium leading-7 text-black  py-2 w-fit rounded-full px-4 font-dm-sans">
                          Electical Services
                        </p>
                        <ul className="pl-5 mt-6 space-y-1 text-base font-normal leading-7 text-gray-700 list-disc list-outside font-dm-sans">
                          <li>
                            Id elementum velit posuere vestibulum lectus id eu
                            sollicitudin.
                          </li>
                          <li>
                            Tellus amet bibendum sit mus phasellus quis duis
                            lacus.
                          </li>
                          <li>
                            Enim eget sagittis, auctor iaculis nulla semper.
                          </li>
                        </ul>
                        <p className="mt-6 text-base font-medium leading-7 text-black  py-2 w-fit rounded-full px-4 font-dm-sans">
                          Civil and Construction Services
                        </p>
                        <ul className="pl-5 mt-6 space-y-1 text-base font-normal leading-7 text-gray-700 list-disc list-outside font-dm-sans">
                          <li>
                            Id elementum velit posuere vestibulum lectus id eu
                            sollicitudin.
                          </li>
                          <li>
                            Tellus amet bibendum sit mus phasellus quis duis
                            lacus.
                          </li>
                          <li>
                            Enim eget sagittis, auctor iaculis nulla semper.
                          </li>
                        </ul>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Add more items here if needed */}
          </div>
        )}
      </div>

      <div>
        <button
          className="text-xl bg-[#23A999] py-3 px-2 font-bold font-nunito-sans text-white w-full text-left flex items-center justify-between mt-4"
          onClick={() => toggleDropdown("bryn-y-rhyd")}
        >
          Bryn-Y-RHYD Farm
          {openDropdown === "bryn-y-rhyd" ? (
            <IoIosArrowUp />
          ) : (
            <IoIosArrowDown />
          )}
        </button>
        {openDropdown === "bryn-y-rhyd" && (
          <div className="mt-6 space-y-5">
            <div className="relative overflow-hidden transition-all duration-200 rounded-lg hover:shadow-lg hover:bg-gray-50 hover:-translate-y-1">
              <div className="px-4">
                <div className="flex items-start lg:items-center">
                  <div className="ml-5">
                    <p className="text-[16px] leading-7 font-normal py-1 mt-3 font-dm-sans text-gray-900">
                      <a href="/projects/bryn-y-rhyd-farm" title="">
                        <p className="text-xl font-dm-sans font-medium">
                          overview
                        </p>

                        <p className="mt-6 text-base font-medium leading-7 text-black  py-2 w-fit rounded-full px-4 font-dm-sans">
                          Electical Services
                        </p>
                        <ul className="pl-5 mt-6 space-y-1 text-base font-normal leading-7 text-gray-700 list-disc list-outside font-dm-sans">
                          <li>
                            Id elementum velit posuere vestibulum lectus id eu
                            sollicitudin.
                          </li>
                          <li>
                            Tellus amet bibendum sit mus phasellus quis duis
                            lacus.
                          </li>
                          <li>
                            Enim eget sagittis, auctor iaculis nulla semper.
                          </li>
                        </ul>
                        <p className="mt-6 text-base font-medium leading-7 text-black  py-2 w-fit rounded-full px-4 font-dm-sans">
                          Civil and Construction Services
                        </p>
                        <ul className="pl-5 mt-6 space-y-1 text-base font-normal leading-7 text-gray-700 list-disc list-outside font-dm-sans">
                          <li>
                            Id elementum velit posuere vestibulum lectus id eu
                            sollicitudin.
                          </li>
                          <li>
                            Tellus amet bibendum sit mus phasellus quis duis
                            lacus.
                          </li>
                          <li>
                            Enim eget sagittis, auctor iaculis nulla semper.
                          </li>
                        </ul>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Add more items here if needed */}
          </div>
        )}
      </div>

      <div>
        <button
          className="text-xl bg-[#23A999] py-3 px-2 font-bold font-nunito-sans text-white w-full text-left flex items-center justify-between mt-4"
          onClick={() => toggleDropdown("manor-farm")}
        >
          Manor Farm
          {openDropdown === "manor-farm" ? (
            <IoIosArrowUp />
          ) : (
            <IoIosArrowDown />
          )}
        </button>
        {openDropdown === "manor-farm" && (
          <div className="mt-6 space-y-5">
            <div className="relative overflow-hidden transition-all duration-200 rounded-lg hover:shadow-lg hover:bg-gray-50 hover:-translate-y-1">
              <div className="px-4">
                <div className="flex items-start lg:items-center">
                  <div className="ml-5">
                    <p className="text-[16px] leading-7 font-normal py-1 mt-3 font-dm-sans text-gray-900">
                      <a href="/projects/manor-farm" title="">
                        <p className="text-xl font-dm-sans font-medium">
                          overview
                        </p>

                        <p className="mt-6 text-base font-medium leading-7 text-black  py-2 w-fit rounded-full px-4 font-dm-sans">
                          Electical Services
                        </p>
                        <ul className="pl-5 mt-6 space-y-1 text-base font-normal leading-7 text-gray-700 list-disc list-outside font-dm-sans">
                          <li>
                            Id elementum velit posuere vestibulum lectus id eu
                            sollicitudin.
                          </li>
                          <li>
                            Tellus amet bibendum sit mus phasellus quis duis
                            lacus.
                          </li>
                          <li>
                            Enim eget sagittis, auctor iaculis nulla semper.
                          </li>
                        </ul>
                        <p className="mt-6 text-base font-medium leading-7 text-black  py-2 w-fit rounded-full px-4 font-dm-sans">
                          Civil and Construction Services
                        </p>
                        <ul className="pl-5 mt-6 space-y-1 text-base font-normal leading-7 text-gray-700 list-disc list-outside font-dm-sans">
                          <li>
                            Id elementum velit posuere vestibulum lectus id eu
                            sollicitudin.
                          </li>
                          <li>
                            Tellus amet bibendum sit mus phasellus quis duis
                            lacus.
                          </li>
                          <li>
                            Enim eget sagittis, auctor iaculis nulla semper.
                          </li>
                        </ul>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Add more items here if needed */}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectsLayout;
