import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const ServiceLayout = () => {
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (dropdown) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  return (
    <div className="lg:col-span-3 bg-[#fafafa] rounded-lg p-3 max-w-md h-fit">
      <div>
        <button
          className="text-xl bg-[#23A999] py-3 px-2 font-bold font-nunito-sans text-white w-full text-left flex items-center justify-between"
          onClick={() => toggleDropdown("electrical")}
        >
          Electrical Services
          {openDropdown === "electrical" ? (
            <IoIosArrowUp />
          ) : (
            <IoIosArrowDown />
          )}
        </button>
        {openDropdown === "electrical" && (
          <div className="mt-6 space-y-5">
            <div className="relative overflow-hidden transition-all duration-200 rounded-lg hover:shadow-lg hover:bg-gray-50 hover:-translate-y-1">
              <div className="px-4">
                <div className="flex items-start lg:items-center">
                  <div className="ml-5">
                    <p className="text-[16px] leading-7 font-normal py-1 font-dm-sans text-gray-900">
                      <a
                        href="/services/electrical-installation-testing"
                        title=""
                      >
                        Electrical Installation and Testing
                        <span
                          className="absolute inset-0"
                          aria-hidden="true"
                        ></span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-hidden transition-all duration-200 rounded-lg hover:shadow-lg hover:bg-gray-50 hover:-translate-y-1">
              <div className="px-4">
                <div className="flex items-start lg:items-center">
                  <div className="ml-5">
                    <p className="text-[16px] leading-7 font-normal py-1 font-dm-sans text-gray-900">
                      <a href="/services/testing-commissioning" title="">
                        Testing and Commissioning
                        <span
                          className="absolute inset-0"
                          aria-hidden="true"
                        ></span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-hidden transition-all duration-200 rounded-lg hover:shadow-lg hover:bg-gray-50 hover:-translate-y-1">
              <div className="px-4">
                <div className="flex items-start lg:items-center">
                  <div className="ml-5">
                    <p className="text-[16px] leading-7 font-normal py-1 font-dm-sans text-gray-900">
                      <a
                        href="/services/scada-system-installation-operation"
                        title=""
                      >
                        SCADA System Installation & Operation
                        <span
                          className="absolute inset-0"
                          aria-hidden="true"
                        ></span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-hidden transition-all duration-200 rounded-lg hover:shadow-lg hover:bg-gray-50 hover:-translate-y-1">
              <div className="px-4">
                <div className="flex items-start lg:items-center">
                  <div className="ml-5">
                    <p className="text-[16px] leading-7 font-normal py-1 font-dm-sans text-gray-900 mt-2.5">
                      <a href="/services/fault-detection-fix" title="">
                        Fault Detection and Fix
                        <span
                          className="absolute inset-0"
                          aria-hidden="true"
                        ></span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-hidden transition-all duration-200 rounded-lg hover:shadow-lg hover:bg-gray-50 hover:-translate-y-1">
              <div className="px-4">
                <div className="flex items-start lg:items-center">
                  <div className="ml-5">
                    <p className="text-[16px] leading-7 font-normal font-dm-sans text-gray-900 mt-2.5">
                      <a
                        href="/services/continuous-monitoring-preventive-maintenance"
                        title=""
                      >
                        Continuous Monitoring & Preventive Maintenance
                        <span
                          className="absolute inset-0"
                          aria-hidden="true"
                        ></span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-hidden transition-all duration-200 rounded-lg hover:shadow-lg hover:bg-gray-50 hover:-translate-y-1">
              <div className="px-4">
                <div className="flex items-start lg:items-center">
                  <div className="ml-5">
                    <p className="text-[16px] leading-7 font-normal font-dm-sans text-gray-900 mt-2.5">
                      <a href="/services/repair-certify" title="">
                        Repair and Certify
                        <span
                          className="absolute inset-0"
                          aria-hidden="true"
                        ></span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-hidden transition-all duration-200 rounded-lg hover:shadow-lg hover:bg-gray-50 hover:-translate-y-1">
              <div className="px-4">
                <div className="flex items-start lg:items-center">
                  <div className="ml-5">
                    <p className="text-[16px] leading-7 font-normal font-dm-sans text-gray-900 mt-2.5">
                      <a href="/services/inventory-management" title="">
                        Inventory Management
                        <span
                          className="absolute inset-0"
                          aria-hidden="true"
                        ></span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div>
        <button
          className="text-xl bg-[#23A999] py-3 px-2 font-bold font-nunito-sans text-white w-full text-left flex items-center justify-between mt-4"
          onClick={() => toggleDropdown("civil")}
        >
          Civil and Construction Services
          {openDropdown === "civil" ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </button>
        {openDropdown === "civil" && (
          <div className="mt-6 space-y-5">
            <div className="relative overflow-hidden transition-all duration-200 rounded-lg hover:shadow-lg hover:bg-gray-50 hover:-translate-y-1">
              <div className="px-4">
                <div className="flex items-start lg:items-center">
                  <div className="ml-5">
                    <p className="text-[16px] leading-7 font-normal py-1 mt-3 font-dm-sans text-gray-900">
                      <a
                        href="/services/trenching-cable-laying-activities"
                        title=""
                      >
                        Trenching & Cable Laying Activities
                        <span
                          className="absolute inset-0"
                          aria-hidden="true"
                        ></span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-hidden transition-all duration-200 rounded-lg hover:shadow-lg hover:bg-gray-50 hover:-translate-y-1">
              <div className="px-4">
                <div className="flex items-start lg:items-center">
                  <div className="ml-5">
                    <p className="text-[16px] leading-7 font-normal py-1 mt-3 font-dm-sans text-gray-900">
                      <a href="/services/piling" title="">
                        Piling
                        <span
                          className="absolute inset-0"
                          aria-hidden="true"
                        ></span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-hidden transition-all duration-200 rounded-lg hover:shadow-lg hover:bg-gray-50 hover:-translate-y-1">
              <div className="px-4">
                <div className="flex items-start lg:items-center">
                  <div className="ml-5">
                    <p className="text-[16px] leading-7 font-normal py-1 mt-3 font-dm-sans text-gray-900">
                      <a href="/services/pv-inverter-structure" title="">
                        PV and Inverter Structure
                        <span
                          className="absolute inset-0"
                          aria-hidden="true"
                        ></span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-hidden transition-all duration-200 rounded-lg hover:shadow-lg hover:bg-gray-50 hover:-translate-y-1">
              <div className="px-4">
                <div className="flex items-start lg:items-center">
                  <div className="ml-5">
                    <p className="text-[16px] leading-7 font-normal py-1 mt-3 font-dm-sans text-gray-900">
                      <a href="/services/ipv-module-installation" title="">
                        IPV Module Installation
                        <span
                          className="absolute inset-0"
                          aria-hidden="true"
                        ></span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        <button
          className="text-xl bg-[#23A999] py-3 px-2 font-bold font-nunito-sans text-white w-full text-left flex items-center justify-between mt-4"
          onClick={() => toggleDropdown("it")}
        >
          It Services
          {openDropdown === "it" ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </button>
        {openDropdown === "it" && (
          <div className="mt-6 space-y-5">
            <div className="relative overflow-hidden transition-all duration-200 rounded-lg hover:shadow-lg hover:bg-gray-50 hover:-translate-y-1">
              <div className="px-4">
                <div className="flex items-start lg:items-center">
                  <div className="ml-5">
                    <p className="text-[16px] leading-7 font-normal py-1 mt-3 font-dm-sans text-gray-900">
                      <a href="/services/ui-ux-design" title="">
                        UI UX Audit , UX research & UI Design
                        <span
                          className="absolute inset-0"
                          aria-hidden="true"
                        ></span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-hidden transition-all duration-200 rounded-lg hover:shadow-lg hover:bg-gray-50 hover:-translate-y-1">
              <div className="px-4">
                <div className="flex items-start lg:items-center">
                  <div className="ml-5">
                    <p className="text-[16px] leading-7 font-normal py-1 mt-3 font-dm-sans text-gray-900">
                      <a href="/services/website-design" title="">
                        Website Design & Development
                        <span
                          className="absolute inset-0"
                          aria-hidden="true"
                        ></span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-hidden transition-all duration-200 rounded-lg hover:shadow-lg hover:bg-gray-50 hover:-translate-y-1">
              <div className="px-4">
                <div className="flex items-start lg:items-center">
                  <div className="ml-5">
                    <p className="text-[16px] leading-7 font-normal py-1 mt-3 font-dm-sans text-gray-900">
                      <a href="/services/software-development" title="">
                        Software Design & development
                        <span
                          className="absolute inset-0"
                          aria-hidden="true"
                        ></span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-hidden transition-all duration-200 rounded-lg hover:shadow-lg hover:bg-gray-50 hover:-translate-y-1">
              <div className="px-4">
                <div className="flex items-start lg:items-center">
                  <div className="ml-5">
                    <p className="text-[16px] leading-7 font-normal py-1 mt-3 font-dm-sans text-gray-900">
                      <a href="/services/mobile-application" title="">
                        Mobile Application Design
                        <span
                          className="absolute inset-0"
                          aria-hidden="true"
                        ></span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative overflow-hidden transition-all duration-200 rounded-lg hover:shadow-lg hover:bg-gray-50 hover:-translate-y-1">
              <div className="px-4">
                <div className="flex items-start lg:items-center">
                  <div className="ml-5">
                    <p className="text-[16px] leading-7 font-normal py-1 mt-3 font-dm-sans text-gray-900">
                      <a href="/services/it" title="">
                        It Support
                        <span
                          className="absolute inset-0"
                          aria-hidden="true"
                        ></span>
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceLayout;
