import React from "react";

const ServiceHero = () => {
  return (
    <div>
      <section className="relative py-12 bg-gray-900 sm:py-16 lg:py-20 xl:py-32">
        <div className="absolute inset-0">
          <img
            className="object-cover w-full h-full"
            src="service-banner1.png"
            alt=""
          />
        </div>

        <div className="absolute inset-0 bg-gray-900/50"></div>

        <div className="relative px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <div className="max-w-5xl mx-auto text-center">
            <p className="text-[#23A999] font-medium text-xl font-nunito-sans">Our services</p>
            <h2 className="text-3xl font-bold text-white font-nunito-sans sm:text-4xl lg:text-5xl">
              Core Services
            </h2>
            <p className="mt-4 text-base font-normal leading-7 text-white sm:mt-6">
              We offer a complete package bespoke to our client's need, handling
              all aspects of the civil / mechanical and electrical installation,
              testing and commissioning. We also maintain the logistics of all
              the electrical equipment as per the requirements. We also offer
              Operational & Maintenance support to our clients. We prioritise
              quality materials and meticulous workmanship to ensure the safety
              and longevity of your solar plant. We work diligently to complete
              your project on time and within budget.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServiceHero;
